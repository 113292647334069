define('jobbio-frontend/components/channel/channel-logo', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    channel: null,
    initial: Ember.computed(function () {
      var initial = this.get('channel.name').charAt(0);
      if (this.get('channel.type') == 'topic') {
        initial = "/" + initial;
      }
      return initial;
    })
  });
});
define("jobbio-frontend/models/location", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    city: _emberData.default.attr("string"),
    state: _emberData.default.attr("string"),
    country: _emberData.default.attr("string"),
    address_line_1: _emberData.default.attr("string"),
    address_line_2: _emberData.default.attr("string"),
    address_line_3: _emberData.default.attr("string"),
    postal_code: _emberData.default.attr("string")
  });
});
define('jobbio-frontend/models/block-content', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    block_id: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    block: _emberData.default.attr(),
    block_content_entry: _emberData.default.attr(),
    title: _emberData.default.attr(),
    subtitle: _emberData.default.attr(),
    text: _emberData.default.attr(),
    text_color: _emberData.default.attr(),
    background_image: _emberData.default.attr(),
    overlay: _emberData.default.attr(),
    height: _emberData.default.attr(),
    background_color: _emberData.default.attr(),
    foreground_image: _emberData.default.attr(),
    image: _emberData.default.attr(),
    icon: _emberData.default.attr(),
    video: _emberData.default.attr(),
    primary_color: _emberData.default.attr(),
    secondary_color: _emberData.default.attr(),
    tertiary_color: _emberData.default.attr(),
    primary_cta_text: _emberData.default.attr(),
    secondary_cta_text: _emberData.default.attr(),
    primary_cta_link: _emberData.default.attr(),
    secondary_cta_link: _emberData.default.attr(),
    location: _emberData.default.attr(),
    country: _emberData.default.attr(),
    address: _emberData.default.attr(),
    city: _emberData.default.attr(),
    state: _emberData.default.attr(),
    post_code: _emberData.default.attr(),
    social_type: _emberData.default.attr(),
    social_link: _emberData.default.attr(),
    job_type_filter: _emberData.default.attr(),
    job_location_filter: _emberData.default.attr(),
    pagination: _emberData.default.attr(),
    margin_top: _emberData.default.attr(),
    margin_right: _emberData.default.attr(),
    margin_bottom: _emberData.default.attr(),
    margin_left: _emberData.default.attr(),
    padding_top: _emberData.default.attr(),
    padding_right: _emberData.default.attr(),
    padding_bottom: _emberData.default.attr(),
    padding_left: _emberData.default.attr(),
    order: _emberData.default.attr()
  });
});
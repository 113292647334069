define('jobbio-frontend/components/head-component', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    cookies: Ember.inject.service(),
    cookieOptions: null,
    flag: Ember.computed(function () {
      if (this.get('cookies').read('location') == 'US') {
        return "/assets/us.svg";
      } else if (this.get('cookies').read('location') == 'CA') {
        return "/assets/ca.svg";
      } else if (this.get('cookies').read('location') == 'UK') {
        return "/assets/uk.svg";
      } else if (this.get('cookies').read('location') == 'IE') {
        return "/assets/ie.svg";
      } else {
        return "/assets/world.svg";
      }
    }),
    logoDark: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/logo_dark.png");
    }),
    logoLight: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/logo_light.png");
    }),
    talentPopupBackground: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/talent_head_popup_background.jpg");
    }),
    init() {
      this._super(...arguments);
      this.set('cookieOptions', { 'path': '/' });
    },
    actions: {
      setCountry: function (country) {
        let cookieService = this.get('cookies');
        cookieService.write('location', country, this.get('cookieOptions'));
        window.location.reload(true);
      }
    }
  });
});
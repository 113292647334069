define('jobbio-frontend/routes/applications/action', ['exports', 'jobbio-frontend/routes/base-route', 'jobbio-frontend/config/environment'], function (exports, _baseRoute, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model(params) {
      var data = { token: params.token, status: params.status };
      var url = _environment.default.APP.API_HOST + '/jobs/' + params.job_id + '/applications/' + params.id + '/update';
      return window.$.ajax({
        url: url,
        type: 'PATCH',
        data: JSON.stringify(data),
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(response => {
        return { success: true, action: params.status, response: response };
      }, () => {
        return { success: false };
      });
    }
  });
});
define('jobbio-frontend/components/job/job-question-select-single', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    selected: Ember.computed('question_answers', function () {
      if (this.get('question_answers')) {
        var answered = false;
        this.get('question_answers').forEach(answer => {
          if (answer.question.id == this.get('question.id')) {
            if (answer.option.id == this.get('option.id')) {
              answered = true;
            }
          }
        });
        return answered;
      }
    })
  });
});
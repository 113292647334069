define('jobbio-frontend/models/nav-list', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    'nav_link_list': _emberData.default.attr(),
    title: _emberData.default.attr(),
    icon: _emberData.default.attr(),
    link_id: _emberData.default.attr(),
    link: _emberData.default.attr()
  });
});
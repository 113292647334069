define('jobbio-frontend/components/job/job-company-logo', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    channel: null,
    initial: Ember.computed(function () {
      return this.get('company.name').charAt(0);
    })
  });
});
define('jobbio-frontend/components/base-component', ['exports', 'jobbio-frontend/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        session: Ember.inject.service('session'),
        cloudfront: _environment.default.APP.CLOUD_FRONT_HOST,
        homepage: _environment.default.APP.WEB_HOST,
        talentDashboard: _environment.default.APP.TALENT_DASHBOARD,
        talentDashboardBio: _environment.default.APP.TALENT_DASHBOARD + '/edit',
        talentDashboardApplications: _environment.default.APP.TALENT_DASHBOARD + '/applications',
        companyDashboard: _environment.default.APP.COMPANY_DASHBOARD,
        consoleDashboard: _environment.default.APP.CONSOLE_DASHBOARD,
        partnerhub: _environment.default.APP.HTTP_PROTOCOL + 'partner.' + _environment.default.APP.DOMAIN
    });
});
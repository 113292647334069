define("jobbio-frontend/models/channel", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    slug: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    active: _emberData.default.attr("number"),
    caption: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    company: _emberData.default.attr(),
    companies: _emberData.default.attr(),
    type: _emberData.default.attr("string"),
    views: _emberData.default.attr("number"),
    impressions: _emberData.default.attr("number"),
    banner: _emberData.default.attr("string"),
    logo: _emberData.default.attr("string"),
    card: _emberData.default.attr("string"),
    updated_at: _emberData.default.attr("date"),
    created_at: _emberData.default.attr("date"),
    popular: _emberData.default.attr(),
    featured: _emberData.default.attr(),
    is_following: _emberData.default.attr(),
    live_jobs: _emberData.default.attr(),
    is_member: _emberData.default.attr()
  });
});
define('jobbio-frontend/components/job/job-question-range', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    range: Ember.computed(function () {
      return this.get('question.options.firstObject');
    }),
    value_from: Ember.computed('value', function () {
      return this.get('value.firstObject');
    }),
    value_to: Ember.computed('value', function () {
      return this.get('value.lastObject');
    }),
    value: Ember.computed('question_answers', function () {
      if (this.get('question_answers')) {
        var answered = [];
        this.get('question_answers').forEach(answer => {
          if (answer.question.id == this.get('question.id')) {
            answered = [answer.value_from, answer.value_to];
          }
        });
        return answered;
      } else {
        return [0, 0];
      }
    })
  });
});
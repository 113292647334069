define('jobbio-frontend/components/search/search-application-submission', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseComponent.default.extend({
        didRender() {
            this._super(...arguments);
            if (this.get('session.isAuthenticated') && this.get('company')) {
                window.$('#searchApplicationSubmission').modal('show');
            }
        }
    });
});
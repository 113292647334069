define('jobbio-frontend/components/channel/channel-banner', ['exports', 'jobbio-frontend/components/channel/channel-component'], function (exports, _channelComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _channelComponent.default.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    backgroundImage: Ember.computed('channel', function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      var imageUrl = this.get('channel').get('banner');
      if (imageUrl === null) {
        var max = 4; // inclusive
        var min = 1; // inclusive
        var number = Math.floor(Math.random() * (max - min + 1)) + min;
        imageUrl = "company-placeholders/company-banner-" + number + ".jpeg";
        return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/" + imageUrl);
      }
      return new Ember.String.htmlSafe(imageUrl);
    }),
    caption: Ember.computed('channel', function () {
      var caption = this.get('channel').get('caption');
      return new Ember.String.htmlSafe(caption);
    }),
    description: Ember.computed('channel', function () {
      var description = this.get('channel').get('description');
      return new Ember.String.htmlSafe(description);
    }),
    isNotCompanyMember: Ember.computed('currentUser', function () {
      return this.get('currentUser.current.user.role') != 'company_member';
    }),
    isAdmin: Ember.computed('currentUser', function () {
      return this.get('session.data.authenticated.jobbio_admin.user.role') == 'jobbio_admin';
    }),
    actions: {
      follow: function (id) {
        var data = { "channel_id": id };
        this.get('store').createRecord('followed-channel', data).save();
        this.set('channel.is_following', true);
      },
      unfollow: function (id) {
        var data = { "channel_id": id };
        this.get('store').createRecord('unfollowed-channel', data).save();
        this.set('channel.is_following', false);
      },
      scrollToClicked: function () {
        // Close nav bar dropdowns
        window.$('.dropdown--active').removeClass('dropdown--active');
      }
    }
  });
});
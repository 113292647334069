define('jobbio-frontend/components/channel/channel-content', ['exports', 'jobbio-frontend/components/channel/channel-component'], function (exports, _channelComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _channelComponent.default.extend({
        store: Ember.inject.service(),
        panels: null,
        init() {
            this._super(...arguments);
            this.get('store').query('branding-content', { type: 'panel', id: this.get('channel.company.id') }).then(response => {
                var panels = [];
                var layoutType = true;
                response.forEach(function (panel) {
                    panel.set('layoutType', layoutType);
                    panels.pushObject(panel);
                    layoutType = !layoutType;
                });
                this.set('panels', panels);
            });
        }
    });
});
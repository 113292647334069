define('jobbio-frontend/models/forgot-password-request', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const Validations = (0, _emberCpValidations.buildValidations)({
        email: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'Email is required!'
        }), (0, _emberCpValidations.validator)('format', {
            type: 'email',
            message: 'Enter a valid email address'
        })]
    });

    exports.default = _emberData.default.Model.extend(Validations, {
        email: _emberData.default.attr('string')
    });
});
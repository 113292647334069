define('jobbio-frontend/components/channel/channel-details', ['exports', 'jobbio-frontend/components/channel/channel-component'], function (exports, _channelComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _channelComponent.default.extend({
    store: Ember.inject.service(),
    myStickyOptions: null,
    currentUser: Ember.inject.service('current-user'),
    isNotTalent: Ember.computed('currentUser', function () {
      return this.get('currentUser.current.user.role') != 'talent';
    }),
    isCompanyMember: Ember.computed('currentUser', function () {
      return this.get('currentUser.current.user.role') == 'company_member';
    }),
    isNotCompanyMember: Ember.computed('currentUser', function () {
      return this.get('currentUser.current.user.role') != 'company_member';
    }),
    isAdmin: Ember.computed('currentUser', function () {
      return this.get('session.data.authenticated.jobbio_admin.user.role') == 'jobbio_admin';
    }),
    init() {
      this._super(...arguments);
      this.set('myStickyOptions', {
        wrapperClassName: '',
        topSpacing: 80,
        bottomSpacing: 560
      });
    },
    actions: {
      follow: function (id) {
        var data = { "channel_id": id };
        this.get('store').createRecord('followed-channel', data).save();
        this.set('channel.is_following', true);
      },
      unfollow: function (id) {
        var data = { "channel_id": id };
        this.get('store').createRecord('unfollowed-channel', data).save();
        this.set('channel.is_following', false);
      },
      addJob: function (id) {
        var data = { "channel_id": id };
        this.get('store').createRecord('added-job', data).save().then(() => {
          window.$('#addJobAlert').modal('show');
        }).catch(xhr => {
          this.set('error', xhr.errors);
        });
      }
    }
  });
});
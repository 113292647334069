define("jobbio-frontend/routes/higherdublin", ["exports", "jobbio-frontend/routes/base-route"], function (exports, _baseRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    redirect() {
      window.location.replace("https://content.jobbio.com/higher/dublin/exhibit");
    }
  });
});
define('jobbio-frontend/components/password/reset-password-alert', ['exports', 'jobbio-frontend/components/base-component', 'jobbio-frontend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    actions: {
      logIn: function () {
        window.$('#resetAlert').modal('hide');
        window.location.href = _environment.default.APP.WEB_HOST + '/user/login';
      }
    }
  });
});
define('jobbio-frontend/initializers/ember-cli-lightbox', ['exports', 'jobbio-frontend/config/environment', 'ember-cli-lightbox/initializers/ember-cli-lightbox'], function (exports, _environment, _emberCliLightbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(...args) {
    return _emberCliLightbox.default.initialize.apply(null, [_environment.default['ember-cli-lightbox'], ...args]);
  }

  exports.default = {
    name: _emberCliLightbox.default.name,
    initialize
  };
});
define('jobbio-frontend/controllers/onboarding/payment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      submitForm() {
        // persist data to back-end here
      }
    }
  });
});
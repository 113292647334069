define('jobbio-frontend/models/company-onboarding', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const Validations = (0, _emberCpValidations.buildValidations)({
        email: (0, _emberCpValidations.validator)('format', {
            type: 'email',
            message: 'Enter a valid email address'
        }),
        password: {
            validators: [(0, _emberCpValidations.validator)('length', {
                min: 6,
                message: 'Password is too short (minimum is 6 characters)'
            }), (0, _emberCpValidations.validator)('format', {
                regex: /^(?=.*[0-9])(?=.*[a-zA-Z])([\S]+)$/,
                message: 'Password must include at least one letter and a number'
            })]
        },
        phone: (0, _emberCpValidations.validator)('number', {
            allowString: true,
            integer: true,
            gt: 5,
            message: 'Enter only numbers'
        })
    });

    exports.default = _emberData.default.Model.extend(Validations, {
        // onboarding step 1
        company_name: _emberData.default.attr(),
        first_name: _emberData.default.attr(),
        last_name: _emberData.default.attr(),
        password: _emberData.default.attr(),
        phone: _emberData.default.attr(),
        email: _emberData.default.attr(),
        longitude: _emberData.default.attr(),
        latitude: _emberData.default.attr(),
        address: _emberData.default.attr(),
        country: _emberData.default.attr(),
        country_short: _emberData.default.attr(),
        city: _emberData.default.attr(),
        state: _emberData.default.attr(),
        country_code: _emberData.default.attr(),
        source: _emberData.default.attr(),
        package: _emberData.default.attr()
    });
});
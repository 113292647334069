define('jobbio-frontend/controllers/talent/questionnaire', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: {
      'id': { refreshModel: true },
      'token': { refreshModel: true },
      'jid': { refreshModel: true }
    }
  });
});
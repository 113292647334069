define('jobbio-frontend/controllers/crunchbase-jobs', ['exports', 'jobbio-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      didAgree() {
        this.set('didAgree', !this.get('didAgree'));
      },
      submit() {
        if (this.get('didAgree')) {
          this.set('submitting', true);
          let first_name = this.get('first_name');
          let last_name = this.get('last_name');
          let email = this.get('email');
          let company_name = this.get('company_name');
          let website = this.get('website');
          let ats = this.get('ats');
          let feed = this.get('feed');

          let data = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            company_name: company_name,
            website: website,
            ats: ats,
            feed: feed
          };

          let self = this;

          window.$.ajax({
            url: _environment.default.APP.API_HOST + '/ats-submission',
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(() => {
            window.$('.submission-message').show();
            self.clearProperties();
            Ember.run.later(function () {
              self.set('submitting', false);
            }, 500);
          }, xhr => {
            var errors = JSON.parse(xhr.responseText);
            self.set('submitting', false);
            self.set('errors', errors);
            window.$('html').animate({
              scrollTop: 0
            }, 800);
          });
        } else {
          window.$('.validation-error-message.agree').show();
        }
      }
    },
    clearProperties: function () {
      this.setProperties({
        first_name: "",
        last_name: "",
        email: "",
        company_name: "",
        website: "",
        ats: "",
        feed: ""
      });
    },
    validateForm: function () {
      Ember.run.schedule("afterRender", this, function () {
        window.$.validator.addMethod("mustContainLetters", function (value) {
          return (/\w*[a-zA-Z]\w*/.test(value)
          );
        });

        var self = this;

        window.$(".registrationForm").validate({
          errorElement: 'label',
          errorClass: "validation-error",
          errorPlacement: function (error, element) {
            if (element.attr("name") == "terms") {
              error.insertAfter(".input-checkbox-wrapper");
            } else {
              error.insertAfter(element);
            }
          },
          rules: {
            first_name: {
              required: true
            },
            last_name: {
              required: true
            },
            email: {
              required: true,
              email: true
            },
            company_name: {
              required: true,
              mustContainLetters: true,
              maxlength: 200
            },
            website: {
              required: true,
              url: true,
              maxlength: 200
            },
            ats: {
              required: true
            },
            feed: {
              required: true,
              url: true
            }
          },
          messages: {
            company_name: {
              required: "We need this information to connect your account.",
              mustContainLetters: "Company Name should contain at least 1 letter."
            },
            website: {
              required: "Website is required.",
              url: "Please enter a valid url starting with https://..."
            },
            ats: {
              required: "Select your ATS.",
              url: "Please enter a valid url starting with https://..."
            },
            feed: {
              required: "We need this information to pull your jobs."
            }
          },
          onfocusout: function (element) {
            self.set(`errors.${element.name}`, '');
            window.$(element).valid();
          },
          invalidHandler: function () {
            window.$('html').animate({
              scrollTop: 0
            }, 800);
          }
        });
      });
    }
  });
});
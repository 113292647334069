define('jobbio-frontend/serializers/company-member', ['exports', 'jobbio-frontend/serializers/drf'], function (exports, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend({
    attrs: {
      user: { embedded: 'always' }
    }
  });
});
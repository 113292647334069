define('jobbio-frontend/components/job/job-complete-bio-pending', ['exports', 'jobbio-frontend/components/base-component', 'jobbio-frontend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    didRender() {
      this._super(...arguments);
      var self = this;
      window.$('#jobCompleteBioPending').on('click', function () {
        self.send('redirect');
      });
      window.$('#jobCompleteBioPending .go-edit-page').on('click', function (e) {
        e.stopPropagation();
        self.send('edit');
      });
      window.$('#jobCompleteBioPending .redirect-job-page').on('click', function (e) {
        e.stopPropagation();
        self.send('redirect');
      });
      window.$('#jobCompleteBioPending .modal-content').on('click', function (e) {
        e.stopPropagation();
      });
    },
    actions: {
      edit: function () {
        window.$('#jobCompleteBioPending').modal('hide');
        window.location.replace(`${_environment.default.APP.TALENT_DASHBOARD}/edit`);
      },
      redirect: function () {
        window.$('#jobCompleteBioPending').modal('hide');
        if (window.location.href.includes('/apply')) {
          window.location.replace(`https://${_environment.default.APP.DOMAIN}/companies/${this.get('job.company.slug')}/jobs/${this.get('job.slug')}`);
        }
      }
    }
  });
});
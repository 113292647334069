define('jobbio-frontend/components/job/job-question-text', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    question: null,
    id: Ember.computed(function () {
      return this.get('question.id');
    }),
    answer: Ember.computed('question_answers', function () {
      if (this.get('question_answers')) {
        var answered = '';
        this.get('question_answers').forEach(answer => {
          if (answer.question.id == this.get('question.id')) {
            answered = answer.text;
          }
        });
        return answered;
      } else {
        return '';
      }
    })
  });
});
define('jobbio-frontend/components/common/popular-company-card', ['exports', 'jobbio-frontend/components/base-component', 'jobbio-frontend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    link: _environment.default.APP.WEB_HOST,
    linkColor: Ember.computed('company', function () {
      return this.get('company.colour') ? this.get('company.colour') : '#444444';
    }),
    init() {
      this._super(...arguments);
    }
  });
});
define('jobbio-frontend/controllers/embed/job', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: {
      'ccuid': { refreshModel: true },
      'source': { refreshModel: true },
      'app_source': { refreshModel: true },
      'job_id': { refreshModel: true }
    }
  });
});
define('jobbio-frontend/routes/channels', ['exports', 'jobbio-frontend/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    titleToken: 'Channels',
    cookies: Ember.inject.service(),
    location: Ember.computed(function () {
      return this.get('cookies').read('location');
    }),
    model: function (params) {
      var channelsState;
      var featured = false,
          partner = false;
      if (params.featured == "true") {
        channelsState = "Trending";
        featured = true;
      } else if (params.partner == "true") {
        channelsState = "Partner";
        partner = true;
      }
      return { state: channelsState, featured: featured, partner: partner, location: this.get('location') };
    }
  });
});
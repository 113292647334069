define("jobbio-frontend/models/company-member", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo("user"),
    company: _emberData.default.belongsTo("company"),
    role: _emberData.default.attr("string"),
    updated_at: _emberData.default.attr("date"),
    created_at: _emberData.default.attr("date")
  });
});
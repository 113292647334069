define('jobbio-frontend/routes/embed/channel', ['exports', 'jobbio-frontend/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function (params) {
      return this.store.findRecord('channel', params.slug);
    }
  });
});
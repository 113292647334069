define('jobbio-frontend/components/job/job-question', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    isText: Ember.computed(function () {
      return this.get('question.type') == 'text';
    }),
    isBool: Ember.computed(function () {
      return this.get('question.type') == 'bool_yn' || this.get('question.type') == 'bool_tf';
    }),
    isRange: Ember.computed(function () {
      return this.get('question.type') == 'range';
    }),
    isSlider: Ember.computed(function () {
      return this.get('question.type') == 'slider';
    }),
    isSelect: Ember.computed(function () {
      return this.get('question.type') == 'single_select' || this.get('question.type') == 'multi_select';
    })
  });
});
define('jobbio-frontend/models/job-content', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        branding: _emberData.default.attr('string'),
        detail: _emberData.default.attr('string'),
        image: _emberData.default.attr('string'),
        impressions: _emberData.default.attr('string'),
        in_bank: _emberData.default.attr('string'),
        text: _emberData.default.attr('string'),
        title: _emberData.default.attr('string'),
        type: _emberData.default.attr('string'),
        video: _emberData.default.attr('string'),
        views: _emberData.default.attr('number'),
        content_id: _emberData.default.attr('number')
    });
});
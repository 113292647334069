define('jobbio-frontend/components/job/job-question-select-multi', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    checked: Ember.computed('question_answers', function () {
      if (this.get('question_answers')) {
        var answered = false;
        this.get('question_answers').forEach(answer => {
          if (answer.question.id == this.get("question.id")) {
            answer.options.forEach(option => {
              if (option.id == this.get('option.id')) {
                answered = true;
              }
            });
          }
        });
        return answered;
      }
    })
  });
});
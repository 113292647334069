define('jobbio-frontend/models/custom-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const inflector = _emberInflector.default.inflector;

  inflector.irregular('talentresponse', 'users');
  inflector.irregular('teammember', 'users');
  inflector.irregular('channel-feed-item', 'companies/activities');

  exports.default = {};
});
define('jobbio-frontend/mixins/in-viewport', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    scrollTimeout: 100,
    enteredViewport: null,

    _setViewport: function () {
      var rect = this.$()[0].getBoundingClientRect();

      this.set('enteredViewport', rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth));
    },

    _setInitialViewport: Ember.on('didInsertElement', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        this._setViewport();
      });
    }),

    _scrollHandler: function () {
      Ember.run.debounce(this, function () {
        this._setViewport();
      }, this.get('scrollTimeout'));
    },

    _bindScroll: Ember.on('didInsertElement', function () {
      var component = this;

      window.$(document).on('touchmove.scrollable', function () {
        component._scrollHandler();
      });

      window.$(window).on('scroll.scrollable', function () {
        component._scrollHandler();
      });
    }),

    _unbindScroll: Ember.on('willDestroyElement', function () {
      window.$(window).off('.scrollable');
      window.$(document).off('.scrollable');
    })
  });
});
define('jobbio-frontend/components/channel/channel-job-item', ['exports', 'jobbio-frontend/components/base-component', 'jobbio-frontend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    job: null,
    link: _environment.default.APP.WEB_HOST,
    salary: Ember.computed(function () {
      var salary = "Not disclosed";
      if (this.get('job.salary_disclosed')) {
        var salaryFrom = this.get('job.salary_from');
        var salaryTo = this.get('job.salary_to');

        if (salaryFrom > 0 && salaryTo > 0) {
          salary = salaryFrom + ' - ' + salaryTo;
        } else if (salaryTo > 0) {
          salary = salaryTo;
        } else if (salaryFrom > 0) {
          salary = salaryFrom;
        }
      }
      return salary;
    }),
    salaryType: Ember.computed(function () {
      var salary = "";
      if (this.get('job.salary_disclosed')) {
        if (this.get('job.salary_type') == 'hour') {
          salary = "p/hr";
        }
      }
      return salary;
    })
  });
});
define('jobbio-frontend/routes/terms', ['exports', 'jobbio-frontend/routes/base-route', 'jobbio-frontend/config/environment'], function (exports, _baseRoute, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model(params) {
      return { image: _environment.default.APP.CLOUD_FRONT_HOST + "images2/site/content-pages", source: params.source };
    }
  });
});
define('jobbio-frontend/helpers/capitalize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.capitalize = capitalize;
  function capitalize(params /*, hash*/) {
    return `${params[0].charAt(0).toUpperCase()}${params[0].slice(1)}`;
  }

  exports.default = Ember.Helper.helper(capitalize);
});
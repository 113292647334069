define('jobbio-frontend/components/search/search-bar', ['exports', 'jobbio-frontend/components/base-component', 'ember-concurrency', 'jobbio-frontend/config/environment'], function (exports, _baseComponent, _emberConcurrency, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    router: Ember.inject.service(),
    windowscroll: Ember.inject.service(),
    googlePlaceAutocompleteService: Ember.inject.service('google-place-autocomplete'),
    jobs: Ember.computed('currentRouteName', function () {
      if (this.get('router.currentRouteName') === 'search.results.index' || this.get('router.currentRouteName') === 'search.results.canonical') {
        return true;
      } else {
        return false;
      }
    }),
    init() {
      this._super(...arguments);
      this.get('windowscroll').on('scroll', () => {
        if (this.get('windowscroll.scrollTop') > 366) {
          window.$('.search-bar.v2').addClass('pos-fixed');
        } else {
          window.$('.search-bar.v2').removeClass('pos-fixed');
        }
      });
    },
    onLocationChanged: Ember.observer('selected', function () {
      let city, country;
      if (this.get('selected')) {
        if (this.get('selected')['searchUrl']) {
          city = this.get('selected.city');
          country = this.get('selected.country');
        } else {
          city = this.get('selected.structured_formatting.main_text');
          country = this.get('selected.terms')[this.get('selected.terms').length - 1].value;
        }
        this.set('location', `${city},${country}`);
      } else {
        this.set('predictions', []);
        this.set('location', 'all');
      }
    }),
    actions: {
      async searchLocations(term) {
        let properties = { input: term, types: ['(cities)'] };
        let predictions = this.get('googlePlaceAutocompleteService').getPlacePredictions(properties);
        this.set('predictions', predictions);
      },
      search: function () {
        if (this.get('jobs') || this.get('jobSearch')) {
          if (this.get('keywords')) {
            this.set('error', false);
            this.get('router').transitionTo('search.results', {
              queryParams: {
                search: this.get('keywords'),
                location: this.get('location'),
                trk: this.get('trk')
              }
            });
          } else {
            this.set('error', true);
          }
        } else {
          this.get('router').transitionTo('search.companies', {
            queryParams: {
              search: this.get('keywords'),
              location: this.get('location'),
              trk: this.get('trk')
            }
          });
        }
      }
    }
  });
});
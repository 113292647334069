define('jobbio-frontend/routes/employer-branding/employer-branding-competition', ['exports', 'jobbio-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      return _environment.default.APP.CLOUD_FRONT_HOST + "images2/site/content-pages";
    }
  });
});
define('jobbio-frontend/routes/legacy-talent-register-apply', ['exports', 'jobbio-frontend/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function (params) {
      this.transitionTo('talent.register', { queryParams: { job: params.id, source: params.source } });
    }
  });
});
define('jobbio-frontend/utils/search', ['exports', 'en-select/utils/search'], function (exports, _search) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _search.default;
    }
  });
});
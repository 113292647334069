define('jobbio-frontend/components/job/job-question-select', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    multi: null,
    isSingle: Ember.computed(function () {
      return this.get('question.type') == 'single_select';
    }),
    init() {
      this._super(...arguments);
      this.set('multi', []);
    }
  });
});
define('jobbio-frontend/components/blog/blog-item', ['exports', 'jobbio-frontend/components/base-component', 'jobbio-frontend/config/environment'], function (exports, _baseComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    link: _environment.default.APP.WEB_HOST,
    linkColor: Ember.computed('company', function () {
      return this.get('company.colour') ? this.get('company.colour') : '#444444';
    }),
    image: Ember.computed('post', function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      var imageUrl = this.get('post').get('image');
      if (imageUrl === null) {
        var max = 12; // inclusive
        var min = 1; // inclusive
        var number = Math.floor(Math.random() * (max - min + 1)) + min;
        imageUrl = `company-placeholders/default-image${number}.jpg`;
        return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/" + imageUrl);
      }
      return new Ember.String.htmlSafe(imageUrl);
    }),
    init() {
      this._super(...arguments);
    }
  });
});
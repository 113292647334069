define('jobbio-frontend/controllers/get-a-demo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    keyUp: function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    },
    actions: {
      createLead() {
        let form = window.$("form#sfLead");
        let formData = form.serializeArray();
        if (this.get('reCaptchaSolved')) {
          this.set('showError', false);
          let submissionForm = document.createElement("form");
          submissionForm.method = "POST";
          submissionForm.action = "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";
          window.$.map(formData, function (n) {
            let element = document.createElement("input");
            element.name = n['name'];
            element.value = n['value'];
            element.setAttribute("type", "hidden");
            submissionForm.appendChild(element);
          });
          document.body.appendChild(submissionForm);
          submissionForm.submit();
        } else {
          this.set('showError', true);
        }
      },
      onCaptchaResolved(reCaptchaResponse) {
        this.set('showError', false);
        this.set('reCaptchaSolved', true);
      },
      onCaptchaExpired() {
        this.get('gRecaptcha').resetReCaptcha();
      }
    }
  });
});
define('jobbio-frontend/components/common/front-head-search', ['exports', 'jobbio-frontend/components/head-component'], function (exports, _headComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _headComponent.default.extend({
    router: Ember.inject.service(),
    windowscroll: Ember.inject.service(),
    cookies: Ember.inject.service(),
    session: Ember.inject.service('session'),
    isTalent: Ember.computed(function () {
      return this.get("session.data.authenticated.talent") !== undefined;
    }),
    isAuthenticated: Ember.computed(function () {
      return Object.keys(this.get("session.data.authenticated")).length !== 0;
    }),
    active: false,
    cookieOptions: null,
    visited: Ember.computed('cookies', function () {
      return this.get('cookies').read('visited');
    }),
    visible: Ember.computed('opacity', function () {
      if (this.get('opacity')) {
        return 'bar--transparent bar--absolute';
      }
      return '';
    }),
    jobs: Ember.computed('currentRouteName', function () {
      return this.get('router.currentRouteName') === 'search.results.index' || this.get('router.currentRouteName') === 'search.results.canonical';
    }),
    dashboardUrl: Ember.computed(function () {
      if (this.get("session.data.authenticated.talent") !== undefined) {
        return this.get('talentDashboardBio');
      } else if (this.get("session.data.authenticated.company_member") !== undefined) {
        return this.get('companyDashboard');
      } else if (this.get("session.data.authenticated.jobbio_admin") !== undefined) {
        return this.get('consoleDashboard');
      }
    }),
    init() {
      this._super(...arguments);
      this.set('cookieOptions', { 'path': '/' });
      this.get('windowscroll').on('scroll', () => {
        if (this.get('windowscroll.scrollTop') > 366) {
          window.$('.header-fixed #menu1').addClass('pos-fixed');
          window.$('.header-fixed').addClass('h-pos-fixed');
        } else {
          window.$('.header-fixed #menu1').removeClass('pos-fixed');
          window.$('.header-fixed').removeClass('h-pos-fixed');
        }
      });
    },
    actions: {
      invalidateSession: function () {
        return this.get('session').invalidate();
      },
      hideCookiePolicyPopup: function () {
        window.$('.cookie-policy-popup').hide();
        let cookieService = this.get('cookies');
        cookieService.write('visited', true, this.get('cookieOptions'));
      }
    }
  });
});
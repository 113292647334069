define('jobbio-frontend/components/blocks/main-block', ['exports', 'jobbio-frontend/components/loader-component'], function (exports, _loaderComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _loaderComponent.default.extend({
    modelType: 'block-content',
    params(self) {
      var params = {};
      params['company_slug'] = self.get('channel.slug');
      return params;
    }
  });
});
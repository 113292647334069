define('jobbio-frontend/components/common/popular-job-type', ['exports', 'jobbio-frontend/components/base-component', 'jobbio-frontend/mixins/in-viewport', 'jobbio-frontend/mixins/image-load'], function (exports, _baseComponent, _inViewport, _imageLoad) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend(_inViewport.default, _imageLoad.default, {
    tagName: 'div',
    classNames: ['col-md-4 col-6'],
    image: Ember.computed(function () {
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning but is escaped
      return new Ember.String.htmlSafe(`${this.get('cloudfront')}images/site/${Ember.String.underscore(this.get('name'))}.jpeg`);
    }),
    elementInViewport: Ember.observer('enteredViewport', function () {
      if (this.get('enteredViewport') && !this.get('lazyImage')) {
        this.set('lazyImage', this.get('image'));
        this.set('imageBackground', `background-image: url('${this.get('image')}'); opacity: 1;`);
      }
    }).on('didInsertElement'),
    actions: {
      searchJobType: function (jobType) {
        window.location.href = `/search?search=${jobType}`;
      }
    }
  });
});
define('jobbio-frontend/utils/strip-keyword', ['exports', 'en-select/utils/strip-keyword'], function (exports, _stripKeyword) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _stripKeyword.default;
    }
  });
});
define('jobbio-frontend/components/channel/channel-component', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    title: Ember.computed('channel.name', function () {
      return this.get('channel.name');
    }),
    // topic and activity channels are effectively the same for ui purporses so
    // we'll treat all activity channels as topic channels
    isTopicChannel: Ember.computed('channel.type', function () {
      return this.get('channel.type') == "topic" || this.get('channel.type') == "activity";
    })
  });
});
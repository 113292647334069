define('jobbio-frontend/components/job/job-other', ['exports', 'jobbio-frontend/components/pagination-component', 'jobbio-frontend/config/environment'], function (exports, _paginationComponent, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    myStickyOptionsLocation: null,
    myStickyOptions: null,
    modelType: 'job-other',
    link: _environment.default.APP.WEB_HOST,
    search: _environment.default.APP.WEB_HOST + '/search',
    company_id: Ember.computed('cursor', function () {
      return this.get('company.id');
    }),
    current_job_id: -1,
    page_size: 6,
    linkToChannel: false, // if set to true will override view more and instead go to channel
    params: Ember.computed('cursor', function () {
      var params = this._super();
      params['job_id'] = this.get('job.id');
      params['company_id'] = this.get('job.company.id');
      return params;
    }),
    // jobs is just an accessor for objects that removes
    // the 'potential' current job from the objects list
    jobs: Ember.computed('cursor', 'current_job_id', function () {
      var jobs = [];
      var objects = this.get('objects');
      for (var i = 0; i < objects.length; i++) {
        var job = objects.objectAt(i);
        if (job.get('id') != this.get('current_job_id')) {
          jobs.push(job);
        }
      }
      return jobs;
    }),
    init() {
      this._super(...arguments);
      this.set('myStickyOptionsLocation', {
        wrapperClassName: '',
        topSpacing: 212,
        bottomSpacing: 1400
      });
      this.set('myStickyOptions', {
        wrapperClassName: '',
        topSpacing: 212,
        bottomSpacing: 590
      });
    },
    actions: {
      jobSelected: function () {
        window.$('html,body').animate({ scrollTop: 0 }, 800);
      }
    }
  });
});
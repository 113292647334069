define('jobbio-frontend/controllers/partnerships/media', ['exports', 'jobbio-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    brands: null,
    myOptions: null,
    init() {
      this._super(...arguments);
      var brands = [{ url: `${_environment.default.APP.CLOUD_FRONT_HOST}images2/site/content-pages/inm-logo.png` }, { url: `${_environment.default.APP.CLOUD_FRONT_HOST}images2/site/content-pages/idg_logo.png` }, { url: `${_environment.default.APP.CLOUD_FRONT_HOST}images2/site/content-pages/venture-beat.png` }, { url: `${_environment.default.APP.CLOUD_FRONT_HOST}images2/site/content-pages/techworld.png` }, { url: `${_environment.default.APP.CLOUD_FRONT_HOST}images2/site/content-pages/computerworld-logo.png` }, { url: `${_environment.default.APP.CLOUD_FRONT_HOST}images2/site/content-pages/infoworld-logo.png` }];
      this.set('brands', brands);
      this.set('myOptions', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          clickable: true,
          bulletActiveClass: 'slide-show-active-bullet'
        },
        keyboard: true,
        slidesPerView: 5,
        loop: true,
        autoplay: {
          delay: 5000
        },
        spaceBetween: -10
      });
    }
  });
});
define('jobbio-frontend/mixins/image-load', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    loaded: false,
    errorThrown: false,

    classNameBindings: ['loaded', 'errorThrown'],

    defaultErrorText: Ember.computed('errorText', function () {
      return Ember.getWithDefault(this, 'errorText', 'Image failed to load');
    }),

    _resolveImage: Ember.on('didInsertElement', function () {
      var component = this;
      var image = component.$('img');
      var isCached = image[0].complete;

      if (!isCached) {
        image.on('load', function () {
          component._imageLoaded();
        });

        image.on('error', function (error) {
          component._imageError(error);
        });
      } else {
        this._imageLoaded();
      }
    }),

    _imageLoaded: function () {
      var component = this;

      Ember.run(function () {
        component.set('loaded', true);
      });
    },

    _imageError: function () {
      var component = this;

      Ember.run(function () {
        component.set('errorThrown', true);
      });
    },

    willDestroy: function () {
      this.$('img').off('load');
      this.$('img').off('error');
    }
  });
});
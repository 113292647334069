define('jobbio-frontend/components/common/all-job-types', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    columns: Ember.computed('allJobTypes', function () {
      return Math.floor(12 / this.get('allJobTypes').length);
    }),
    allJobTypes: Ember.computed('jobTypes', function () {
      var jobTypes = [];
      var column = [];
      if (this.get('jobTypes')) {
        this.get('jobTypes').forEach((jobType, index) => {
          if (index % 16 === 0 && index != 0) {
            jobTypes.push(column);
            column = [];
          }
          column.push(jobType);
        });
        jobTypes.push(column);
      }
      return jobTypes;
    }),
    init() {
      this._super(...arguments);
      this.get('store').query('job-type', {}).then(response => {
        this.set('jobTypes', response);
      });
    },
    actions: {
      searchJobType: function (jobType) {
        window.location.href = `/search?search=${jobType}`;
      }
    }
  });
});
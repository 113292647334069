define('jobbio-frontend/components/blocks/block-14', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    style: Ember.computed(function () {
      return new Ember.String.htmlSafe(`
      background-color: ${this.get('object.background_color')};
      padding: ${this.get('object.padding_top')}px ${this.get('object.padding_right')}px ${this.get('object.padding_bottom')}px ${this.get('object.padding_left')}px;
      margin: ${this.get('object.margin_top')}px ${this.get('object.margin_right')}px ${this.get('object.margin_bottom')}px ${this.get('object.margin_left')}px;
    `);
    }),
    didRender() {
      this._super(...arguments);
      window.$('.tabs li.active').each(function () {
        window.mr.tabs.activateTab(this);
      });
      window.$('.tabs > li').on('click', function () {
        var clickedTab = window.$(this),
            hash;
        window.mr.tabs.activateTab(clickedTab);

        // Update the URL bar if the currently clicked tab has an ID
        if (clickedTab.is('[id]')) {
          // Create the hash from the tab's ID
          hash = '#' + clickedTab.attr('id');
          // Check we are in a newish browser with the history API
          if (history.pushState) {
            history.pushState(null, null, hash);
          } else {
            location.hash = hash;
          }
        }
      });
    }
  });
});
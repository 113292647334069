define('jobbio-frontend/components/search/search-job-list-item', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    selectedJobBorder: Ember.computed('selectedJob', function () {
      return this.get('selectedJob.id') == this.get('job.id') ? new Ember.String.htmlSafe('border-color: #ff4f4f;') : new Ember.String.htmlSafe('border-color: #ececec;');
    }),
    actions: {
      selectJob: function (job) {
        if (job.get('redirect') && job.get('id') != this.get('selectedJob.id')) window.open(job.get('redirect'), '_blank');
        this.set('selectedJob', job);
      }
    }
  });
});
define('jobbio-frontend/components/channel/channel-cards', ['exports', 'jobbio-frontend/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    modelType: 'channel',
    featured: false,
    popular: false,
    partner: false,
    location: '',
    page_size: 18,
    params: Ember.computed('cursor', function () {
      var params = this._super();
      params['featured'] = this.get('featured');
      params['popular'] = this.get('popular');
      params['partner'] = this.get('partner');
      params['location'] = this.get('location');
      if (this.get('location') != 'ALL') {
        params['order'] = true;
      }
      return params;
    })
  });
});
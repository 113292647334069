define('jobbio-frontend/helpers/highlight', ['exports', 'en-select/helpers/highlight'], function (exports, _highlight) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _highlight.default;
    }
  });
  Object.defineProperty(exports, 'highlight', {
    enumerable: true,
    get: function () {
      return _highlight.highlight;
    }
  });
});
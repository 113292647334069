define('jobbio-frontend/components/common/common-card-image', ['exports', 'jobbio-frontend/components/base-component', 'jobbio-frontend/config/environment', 'jobbio-frontend/mixins/in-viewport', 'jobbio-frontend/mixins/image-load'], function (exports, _baseComponent, _environment, _inViewport, _imageLoad) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend(_inViewport.default, _imageLoad.default, {
    tagName: 'div',
    classNames: null,
    link: _environment.default.APP.WEB_HOST,
    image: Ember.computed('company', function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      var imageUrl = this.get('company').get('image_card');
      if (imageUrl === null) {
        var max = 12; // inclusive
        var min = 1; // inclusive
        var number = Math.floor(Math.random() * (max - min + 1)) + min;
        imageUrl = `company-placeholders/default-image${number}.jpg`;
        return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/" + imageUrl);
      }
      return new Ember.String.htmlSafe(imageUrl);
    }),
    imageBackground: null,
    linkColor: Ember.computed('company', function () {
      return this.get('company.colour') ? this.get('company.colour') : '#444444';
    }),
    elementInViewport: Ember.observer('enteredViewport', function () {
      if (this.get('enteredViewport') && !this.get('lazyImage')) {
        this.set('lazyImage', this.get('image'));
        this.set('imageBackground', `background-image: url('${this.get('image')}');`);
      }
    }).on('didInsertElement'),
    init() {
      this._super(...arguments);
      if (this.get('home')) {
        this.set('classNames', ['img']);
      } else {
        this.set('classNames', null);
      }
    },
    didRender() {
      this._super(...arguments);
      if (!(this.get('company.image_card') || this.get('company.image_banner'))) {
        window.$(`#companyCardsSlugCTA_${this.get('company.id')}`).attr('data-overlay', '3');
      }
    }
  });
});
define('jobbio-frontend/components/common/company-list-item', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    noJobsStyle: Ember.computed(function () {
      if (this.get('company.live_jobs') == 0) {
        return 'line-height: 35px;';
      }
    })
  });
});
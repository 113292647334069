define('jobbio-frontend/components/blog/blog-posts', ['exports', 'jobbio-frontend/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    modelType: 'blog-post',
    params: Ember.computed('cursor', function () {
      var params = this._super();
      params['page_size'] = 3;
      return params;
    })
  });
});
define("jobbio-frontend/models/job", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    //company: DS.belongsTo('company'),
    title: _emberData.default.attr("string"),
    slug: _emberData.default.attr("string"),
    description: _emberData.default.attr("string"),
    contract: _emberData.default.attr("string"),
    level: _emberData.default.attr("string"),
    city: _emberData.default.attr("string"),
    country: _emberData.default.attr("string"),
    postal_code: _emberData.default.attr("string"),
    state: _emberData.default.attr("string"),
    content: _emberData.default.attr(),
    company: _emberData.default.attr(),
    type: _emberData.default.attr(),
    questions: _emberData.default.attr(),
    location: _emberData.default.attr(),
    salary_from: _emberData.default.attr("number"),
    salary_to: _emberData.default.attr("number"),
    salary_type: _emberData.default.attr("string"),
    salary_disclosed: _emberData.default.attr(),
    status: _emberData.default.attr("string"),
    views: _emberData.default.attr("number"),
    impressions: _emberData.default.attr("number"),
    applications: _emberData.default.attr("number"),
    published_at: _emberData.default.attr("date"),
    updated_at: _emberData.default.attr("date"),
    created_at: _emberData.default.attr("date"),
    has_applied: _emberData.default.attr(),
    skills: _emberData.default.attr(),
    redirect: _emberData.default.attr("string"),
    application_status: _emberData.default.attr(),
    application_answers: _emberData.default.attr(),
    application_id: _emberData.default.attr(),
    is_recruiter: _emberData.default.attr(),
    jobkey: _emberData.default.attr()
  });
});
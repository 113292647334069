define('jobbio-frontend/routes/partnerships/talent', ['exports', 'jobbio-frontend/routes/base-route', 'jobbio-frontend/config/environment'], function (exports, _baseRoute, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model() {
      return _environment.default.APP.CLOUD_FRONT_HOST + "images2/site/content-pages";
    },
    actions: {
      didTransition() {
        Ember.run.scheduleOnce('afterRender', this, function () {
          window.$('.slides').flickity({
            wrapAround: true,
            pageDots: false,
            arrowShape: 'M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z'
          });
          // Validate email field
          window.$('#email').on('keypress keyup blur', function () {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(window.$('#email').val())) {
              window.$('#email-validation-error').show();
            } else {
              window.$('#email-validation-error').hide();
            }
          });
          // Validate phone field
          window.$('#phone').on('keypress keyup blur', function (event) {
            if (event.which < 48 || event.which > 57) {
              event.preventDefault();
            }
            if (!/^\d*$/.test(window.$('#phone').val())) {
              window.$('#phone-validation-error').show();
            } else {
              window.$('#phone-validation-error').hide();
            }
          });
          window.$('form#w0').submit(function (event) {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(window.$('#email').val()) || !/^\d*$/.test(window.$('#phone').val())) {
              event.preventDefault();
            }
          });
        });
      }
    }
  });
});
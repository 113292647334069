define('jobbio-frontend/components/job/job-location', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    location: null,
    gMap: Ember.inject.service(),
    address: Ember.computed(function () {
      return this.get('location.address');
    }),
    latitude: Ember.computed(function () {
      return this.get('location.latitude');
    }),
    longitude: Ember.computed(function () {
      return this.get('location.longitude');
    })
  });
});
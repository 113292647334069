define('jobbio-frontend/components/job/job-question-bool', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    question: null,
    checked: Ember.computed('question_answers', function () {
      if (this.get('question_answers')) {
        var answered = '';
        this.get('question_answers').forEach(answer => {
          if (answer.question.id == this.get('question.id')) {
            answered = answer.bool;
          }
        });
        return answered;
      } else {
        return true;
      }
    }),
    actions: {
      checkChanged: function () {
        this.set('checked', !this.get('checked'));
      }
    }
  });
});
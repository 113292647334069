define('jobbio-frontend/components/blocks/block-11', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    myOptions: null,
    init() {
      this._super(...arguments);
      this.set('myOptions', {
        pagination: {
          clickable: true,
          bulletActiveClass: 'slide-show-active-bullet'
        },
        centered: true,
        keyboard: true,
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 5000
        },
        spaceBetween: 30
      });
    },
    style: Ember.computed(function () {
      return new Ember.String.htmlSafe(`
      background-color: ${this.get('object.background_color')};
      padding: ${this.get('object.padding_top')}px ${this.get('object.padding_right')}px ${this.get('object.padding_bottom')}px ${this.get('object.padding_left')}px;
      margin: ${this.get('object.margin_top')}px ${this.get('object.margin_right')}px ${this.get('object.margin_bottom')}px ${this.get('object.margin_left')}px;
    `);
    })
  });
});
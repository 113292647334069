define('jobbio-frontend/components/product/product-pricing', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    store: Ember.inject.service(),
    init() {
      this._super(...arguments);
      var self = this;
      this.get('store').queryRecord('package', 'single-job-post-1').then(function (single) {
        self.set('singleJob', single);
      });
      this.get('store').queryRecord('package', 'onboarding-subscription').then(function (subscription) {
        self.set('subscription', subscription);
      });
    },
    actions: {
      seeAllFeatures() {
        window.$('html, body').animate({
          scrollTop: window.$("#product-features").offset().top
        }, 800);
      }
    }
  });
});
define('jobbio-frontend/components/common/front-search', ['exports', 'jobbio-frontend/components/base-component', 'ember-concurrency', 'jobbio-frontend/config/environment'], function (exports, _baseComponent, _emberConcurrency, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    location: '',
    router: Ember.inject.service(),
    googlePlaceAutocompleteService: Ember.inject.service('google-place-autocomplete'),
    frontPageBanner: Ember.computed(function () {
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning but is escaped
      return new Ember.String.htmlSafe(`${this.get('cloudfront')}images/site/homepage-banner-airbnb.jpg`);
    }),
    onLocationChanged: Ember.observer('selected', function () {
      let city, country;
      if (this.get('selected')) {
        city = this.get('selected.structured_formatting.main_text');
        country = this.get('selected.terms')[this.get('selected.terms').length - 1].value;
        this.set('location', `${city},${country}`);
      } else {
        this.set('predictions', []);
        this.set('location', 'all');
      }
    }),
    actions: {
      async searchLocations(term) {
        let properties = { input: term, types: ['(cities)'] };
        let predictions = this.get('googlePlaceAutocompleteService').getPlacePredictions(properties);
        this.set('predictions', predictions);
      },
      search: function () {
        if (this.get('keywords')) {
          this.get('router').transitionTo('search.results', {
            queryParams: {
              search: this.get('keywords'),
              location: this.get('location')
            }
          });
        } else {
          this.set('error', true);
        }
      }
    }
  });
});
define('jobbio-frontend/controllers/user/forgot-password', ['exports', 'jobbio-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    setting: false,
    errors: null,
    cloudfront: _environment.default.APP.CLOUD_FRONT_HOST,
    backgroundImage: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/login_background.jpg");
    }),
    actions: {
      forgotPassword() {
        var self = this;
        var record = this.get('store').createRecord('forgot-password-request', { email: this.get('email') });
        record.validate().then(({ validations }) => {
          var errors = {};
          validations.get('errors').forEach(error => {
            errors[error.attribute] = error.message;
          });
          self.set('errors', errors);
          if (validations.get('isValid')) {
            self.set('setting', true);
            record.save().then(() => {
              self.set('email', '');
              Ember.run.later(function () {
                self.set('setting', false);
              }, 500);
              window.$('#forgotAlert').modal('show');
            }).catch(xhr => {
              self.set('setting', false);
              self.set('error', xhr.message);
            });
          }
        });
      }
    }
  });
});
define("jobbio-frontend/routes/workhappy100", ["exports", "jobbio-frontend/routes/base-route"], function (exports, _baseRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    beforeModel() {
      window.location.href = "https://content.jobbio.com/work-happy-100";
    }
  });
});
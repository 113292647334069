define('jobbio-frontend/components/search/search-jobs', ['exports', 'jobbio-frontend/components/active-pagination-component', 'ember-concurrency'], function (exports, _activePaginationComponent, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activePaginationComponent.default.extend({
    store: Ember.inject.service(),
    modelType: 'job',
    params: Ember.computed('cursor', 'type', 'location', 'contract', 'level', 'searchTerm', function () {
      var params = this._super();
      if (this.get('location')) {
        params['location'] = this.get('location');
      }
      if (this.get('level')) params['level'] = this.get('level');
      if (this.get('contract')) params['contract'] = this.get('contract');
      if (this.get('date_posted') && this.get('date_posted') !== 'all') params['date_posted'] = this.get('date_posted');
      if (this.get('type') != 'all' && this.get('type') != '') {
        params['type'] = this.get('type');
      }
      if (this.get('searchTerm') && this.get('searchTerm') !== ' ') {
        params['es'] = true;
      } else {
        params['es'] = false;
      }
      return params;
    }),
    searchTermChanged: Ember.observer('type', 'contract', 'level', 'date_posted', function () {
      if (this.get('resetAllowed')) {
        this.get('resetSearch').perform();
      }
    }),
    newSearch: Ember.observer('trk', function () {
      if (this.get('resetAllowed')) {
        this.get('resetSearch').perform();
      }
    }),
    init() {
      this._super(...arguments);
    },
    actions: {
      loaded() {
        if (!this.get('hasPrevious')) {
          if (this.get('objects.firstObject')) {
            this.set('jobs', this.get('objects'));
            this.set('selectedJob', this.get('objects.firstObject'));
          } else {
            this.setProperties({
              jobs: [],
              selectedJob: null
            });
          }
        }
      }
    }
  });
});
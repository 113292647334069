define('jobbio-frontend/components/channel/channel-companies', ['exports', 'jobbio-frontend/components/channel/channel-feed'], function (exports, _channelFeed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _channelFeed.default.extend({
    modelType: 'channel-company',
    myStickyOptions: null,
    init() {
      this._super(...arguments);
      this.set('myStickyOptions', {
        wrapperClassName: '',
        topSpacing: 80,
        bottomSpacing: 560
      });
    }
  });
});
define('jobbio-frontend/controllers/embeddable', ['exports', 'jobbio-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: { 'container': { refreshModel: true },
      'type': { refreshModel: true },
      'slug': { refreshModel: true }, // channel / company slug
      'id': { refreshModel: true }, // job id
      'banner': { refreshModel: true },
      'heroLine': { refreshModel: true },
      'companyUrlTemplate': { refreshModel: true },
      'subLine': { refreshModel: true },
      'showFilterByCompanies': { refreshModel: true },
      'showAddJobToChannel': { refreshModel: true },
      'showDiscoverMoreChannels': { refreshModel: true },
      'prefixCSS': { refreshModel: true }
    },
    // below are default settings for the above queryParams
    container: 'div1',
    type: 'channel',
    slug: 'techjobs',
    id: 1,
    banner: Ember.computed(function () {
      return new Ember.String.htmlSafe(_environment.default.APP.CLOUD_FRONT_HOST + "images/site/front_page_banner.jpg");
    }),
    heroLine: 'Jobbio',
    companyUrlTemplate: _environment.default.APP.DOMAIN,
    subLine: 'find your future',
    showFilterByCompanies: 0,
    showAddJobToChannel: 1,
    showDiscoverMoreChannels: 1,
    // potential models
    job: null,
    channel: null,
    prefixCSS: false,
    isJob: Ember.computed(function () {
      return this.get('type') == 'job';
    })
  });
});
define('jobbio-frontend/components/company/register-left-panel', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseComponent.default.extend({
    init() {
      this._super(...arguments);
      if (this.get("model.package")) {
        window.$('.nav-container')[0].remove();
      }
    }
  });
});
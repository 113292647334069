define('jobbio-frontend/routes/companies', ['exports', 'jobbio-frontend/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    titleToken: 'Companies',
    cookies: Ember.inject.service(),
    location: Ember.computed(function () {
      return this.get('cookies').read('location');
    }),
    model: function (params) {
      var companiesState;
      var featured = false,
          popular = false;
      if (params.featured == "true") {
        companiesState = "Latest";
        featured = true;
      } else if (params.popular == "true") {
        companiesState = "Popular";
        popular = true;
      }
      return { state: companiesState, popular: popular, featured: featured, location: this.get('location') };
    }
  });
});
define('jobbio-frontend/models/package', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    hero_title: _emberData.default.attr(),
    subtitle: _emberData.default.attr(),
    onboarding_message: _emberData.default.attr(),
    cta: _emberData.default.attr(),
    image: _emberData.default.attr(),
    package_details: _emberData.default.attr(),
    slug: _emberData.default.attr(),
    package_page_details: _emberData.default.attr(),
    secondary_title: _emberData.default.attr(),
    secondary_subtitle: _emberData.default.attr(),
    secondary_text: _emberData.default.attr(),
    logos: _emberData.default.attr(),
    footer: _emberData.default.attr(),
    counter: _emberData.default.attr(),
    price: _emberData.default.attr(),
    currency: _emberData.default.attr(),
    form_title: _emberData.default.attr(),
    description: _emberData.default.attr(),
    customizable: _emberData.default.attr(),
    type: _emberData.default.attr()
  });
});
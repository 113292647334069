define('jobbio-frontend/components/common/talent-head-popup', ['exports', 'jobbio-frontend/components/head-component'], function (exports, _headComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _headComponent.default.extend({
        actions: {
            jobSearch: function () {
                // Reset searched type filter
                window.$('#search_job_type_input').val('');
                window.$('.search-job-type').show();
            }
        }
    });
});
define('jobbio-frontend/models/company-onboarding-job', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // onboarding step 2
    job_type_id: _emberData.default.attr(),
    title: _emberData.default.attr(),
    address: _emberData.default.attr(),
    country: _emberData.default.attr(),
    country_short: _emberData.default.attr(),
    city: _emberData.default.attr(),
    state: _emberData.default.attr(),
    post_code: _emberData.default.attr(),
    oid: _emberData.default.attr()
  });
});
define('jobbio-frontend/routes/legacy-talent-register-follow', ['exports', 'jobbio-frontend/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    queryParams: { source: { refreshModel: true } },
    source: '',
    model: function (params) {
      this.transitionTo('talent.register', { queryParams: { channel: params.slug, source: params.source } });
    }
  });
});
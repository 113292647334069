define('jobbio-frontend/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    load() {
      if (this.get('session.data.authenticated.talent')) {
        let userId = this.get('session.data.authenticated.talent.user.id');
        if (!Ember.isEmpty(userId)) {
          return this.get('store').findRecord('talentresponse', userId).then(response => {
            this.set('current', response.get('talent'));
          });
        } else {
          return Ember.RSVP.resolve();
        }
      } else if (this.get('session.data.authenticated.company_member')) {
        let userId = this.get('session.data.authenticated.company_member.user.id');
        if (!Ember.isEmpty(userId)) {
          return this.get('store').findRecord('teammember', userId).then(response => {
            this.set('current', response.get('company_member'));
          });
        } else {
          return Ember.RSVP.resolve();
        }
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });
});
define('jobbio-frontend/models/company-onboarding-payment', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	const Validations = (0, _emberCpValidations.buildValidations)({
		name: (0, _emberCpValidations.validator)('presence', {
			presence: true,
			message: 'This is a required field!'
		}),
		email: (0, _emberCpValidations.validator)('format', {
			allowBlank: true,
			type: 'email',
			message: 'Enter a valid email address'
		})
	});

	exports.default = _emberData.default.Model.extend(Validations, {
		// onboarding step 3
		name: _emberData.default.attr('string'),
		email: _emberData.default.attr('string'),
		package: _emberData.default.attr(),
		amount: _emberData.default.attr(),
		coupon: _emberData.default.attr(),
		payment_method_id: _emberData.default.attr(),
		payment_intent_id: _emberData.default.attr(),
		oid: _emberData.default.attr(),
		card_token: _emberData.default.attr()
	});
});
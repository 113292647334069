define('jobbio-frontend/components/active-pagination-component', ['exports', 'jobbio-frontend/components/pagination-component', 'ember-concurrency'], function (exports, _paginationComponent, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    resetAllowed: true,
    isLoading: Ember.computed('loading', 'resetSearch.isRunning', function () {
      return this.get('loading') | this.get('resetSearch.isRunning');
    }),
    didRender() {
      if (this.get('autoLoad') && this.get('hasLoadedInitialy') == false) {
        this.send('loadMore');
      }
    },
    // searchTermChanged: observer('searchTerm', 'type', function() {
    //   if(this.get('resetAllowed')){
    //     this.set('objects', []);
    //     this.get('resetSearch').perform();
    //   }
    // }),
    resetSearch: (0, _emberConcurrency.task)(function* () {
      // wait while user is typing
      // yield timeout(500);

      this.set('cursor', '');
      this.setProperties({
        hasLoadedInitialy: false,
        hasMore: true,
        objects: []
      });
      this.send('loadMore');
    }).restartable()
  });
});
define('jobbio-frontend/components/search/search-companies', ['exports', 'jobbio-frontend/components/active-pagination-component'], function (exports, _activePaginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activePaginationComponent.default.extend({
    modelType: 'company',
    page_size: 18,
    params: Ember.computed('cursor', function () {
      var params = this._super();
      params['location'] = this.get('location');
      return params;
    }),
    searchChanged: Ember.observer('trk', function () {
      if (this.get('resetAllowed')) {
        this.get('resetSearch').perform();
      }
    }),
    init() {
      this._super(...arguments);
    }
  });
});
define('jobbio-frontend/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    isTalent: Ember.computed(function () {
      return this.get("session.data.authenticated.talent") !== undefined;
    }),
    isCompany: Ember.computed(function () {
      return this.get("session.data.authenticated.company_member") !== undefined;
    }),
    isAdmin: Ember.computed(function () {
      return this.get("session.data.authenticated.jobbio_admin") !== undefined;
    }),
    embeded: Ember.computed('currentRouteName', function () {
      if (this.get('currentRouteName') === 'embed.channel' || this.get('currentRouteName') === 'embed.job' || this.get('currentRouteName') === 'company.onboarding.index' || this.get('currentRouteName') === 'company.onboarding.job' || this.get('currentRouteName') === 'company.onboarding.payment') {
        return true;
      } else {
        return false;
      }
    }),
    apply: Ember.computed('currentRouteName', function () {
      if (this.get('currentRouteName') === 'job.apply') {
        return true;
      } else {
        return false;
      }
    }),
    opacity: Ember.computed('currentRouteName', function () {
      if (this.get('currentRouteName') === 'index' || this.get('currentRouteName') === 'channel' || this.get('currentRouteName') === 'product.offering' || this.get('currentRouteName') === 'get-a-demo') {
        return true;
      } else {
        return false;
      }
    }),
    channel: Ember.computed('currentRouteName', function () {
      if (this.get('currentRouteName') === 'channel') {
        return true;
      } else {
        return false;
      }
    }),
    isTalentRegister: Ember.computed('currentRouteName', function () {
      if (this.get('currentRouteName') === 'talent.register') {
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('job')) {
          return true;
        }
      }
      return false;
    }),
    redirect: Ember.computed('currentRouteName', function () {
      if (this.get('currentRouteName') === 'higherdublin' || this.get('currentRouteName') === 'workhappy100') {
        return true;
      } else {
        return false;
      }
    }),
    isProductOffering: Ember.computed('currentRouteName', function () {
      if (this.get('currentRouteName') === 'product.offering') {
        return true;
      } else {
        return false;
      }
    }),
    isPricing: Ember.computed('currentRouteName', function () {
      if (this.get('currentRouteName') === 'pricing') {
        return true;
      } else {
        return false;
      }
    }),
    isSearch: Ember.computed('currentRouteName', function () {
      if (this.get('currentRouteName') === 'search.results.index' || this.get('currentRouteName') === 'search.results.canonical' || this.get('currentRouteName') === 'search.companies') {
        return true;
      } else {
        return false;
      }
    }),
    customCss: Ember.computed('currentRouteName', function () {
      var customCss = '';
      if (this.get('currentRouteName') === 'talent.register') {
        customCss = 'talent-register-head';
      }
      return customCss;
    }),
    // Close dropdowns on the top nav when route get changed
    onRouteChanged: Ember.observer('currentRouteName', function () {
      window.$('#menu1 .dropdown--active').removeClass('dropdown--active');

      let interComRoutes = ['get-a-demo', 'product.offering', 'company.onboarding.index', 'company.onboarding.job', 'company.onboarding.payment'];
      if (interComRoutes.includes(this.get('currentRouteName'))) {
        window.$('#intercom-container').show();
        window.$('#launcher').hide();
        window.$('#webWidget').hide();
      } else {
        window.$('#intercom-container').hide();
        window.$('#launcher').show();
        window.$('#webWidget').show();
      }
    }),
    demo: Ember.computed('currentRouteName', function () {
      if (this.get('currentRouteName') === 'get-a-demo') {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });
});
define('jobbio-frontend/components/svgs/toggle-options', ['exports', 'en-select/components/svgs/toggle-options'], function (exports, _toggleOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _toggleOptions.default;
    }
  });
});
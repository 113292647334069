define('jobbio-frontend/components/talent/register-application-pop', ['exports', 'jobbio-frontend/components/base-component'], function (exports, _baseComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseComponent.default.extend({
        router: Ember.inject.service(),
        logoDark: Ember.computed(function () {
            // Produces
            // Binding style attributes may introduce cross-site scripting vulnerabilities warning
            // but is escaped
            return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/logo_dark.png");
        }),
        init() {
            this._super(...arguments);
        }
    });
});
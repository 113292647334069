define('jobbio-frontend/mirage-factories/post', ['exports', 'ember-infinity/mirage-factories/post'], function (exports, _post) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _post.default;
    }
  });
});
define('jobbio-frontend/routes/legacy-company-terms', ['exports', 'jobbio-frontend/routes/base-route'], function (exports, _baseRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function () {
      this.transitionTo('company-terms');
    }
  });
});
define('jobbio-frontend/controllers/channel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    router: Ember.inject.service(),
    isTalent: Ember.computed(function () {
      return this.get("session.data.authenticated.talent") != undefined;
    }),
    isCompany: Ember.computed(function () {
      return this.get("session.data.authenticated.company_member") != undefined;
    }),
    isAdmin: Ember.computed(function () {
      return this.get("session.data.authenticated.jobbio_admin") != undefined;
    }),
    embeded: Ember.computed('router.currentRouteName', function () {
      if (this.get('router.currentRouteName') == 'embed.channel' || this.get('router.currentRouteName') == 'embed.job' || this.get('router.currentRouteName') == 'company.onboarding.index' || this.get('router.currentRouteName') == 'company.onboarding.job' || this.get('router.currentRouteName') == 'company.onboarding.payment') {
        return true;
      } else {
        return false;
      }
    }),
    apply: Ember.computed('router.currentRouteName', function () {
      if (this.get('crouter.urrentRouteName') == 'job.apply') {
        return true;
      } else {
        return false;
      }
    }),
    opacity: Ember.computed('router.currentRouteName', function () {
      if (this.get('router.currentRouteName') == 'index' || this.get('router.currentRouteName') == 'channel' || this.get('router.currentRouteName') == 'product.offering' || this.get('router.currentRouteName') == 'get-a-demo') {
        return true;
      } else {
        return false;
      }
    }),
    isTalentRegister: Ember.computed('router.currentRouteName', function () {
      if (this.get('router.currentRouteName') == 'talent.register') {
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('job')) {
          return true;
        }
      }
      return false;
    }),
    redirect: Ember.computed('router.currentRouteName', function () {
      if (this.get('router.currentRouteName') == 'higherdublin' || this.get('router.currentRouteName') == 'workhappy100') {
        return true;
      } else {
        return false;
      }
    }),
    isProductOffering: Ember.computed('router.currentRouteName', function () {
      if (this.get('router.currentRouteName') == 'product.offering') {
        return true;
      } else {
        return false;
      }
    }),
    customCss: Ember.computed('router.currentRouteName', function () {
      var customCss = '';
      if (this.get('router.currentRouteName') == 'talent.register') {
        customCss = 'talent-register-head';
      }
      return customCss;
    }),
    // Close dropdowns on the top nav when route get changed
    onRouteChanged: Ember.observer('router.currentRouteName', function () {
      window.$('#menu1 .dropdown--active').removeClass('dropdown--active');
    }),
    demo: Ember.computed('router.currentRouteName', function () {
      if (this.get('router.currentRouteName') == 'get-a-demo') {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });
});
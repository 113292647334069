define('jobbio-frontend/models/reset-password-request', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const Validations = (0, _emberCpValidations.buildValidations)({
        email: {
            validators: [(0, _emberCpValidations.validator)('presence', {
                presence: true,
                message: 'Email is required!'
            }), (0, _emberCpValidations.validator)('format', {
                type: 'email',
                message: 'Enter a valid email address'
            })]
        },
        new_password: {
            validators: [(0, _emberCpValidations.validator)('presence', {
                presence: true,
                message: 'Password is required!'
            }), (0, _emberCpValidations.validator)('length', {
                min: 6,
                message: 'Password is too short (minimum is 6 characters)'
            }), (0, _emberCpValidations.validator)('format', {
                regex: /^(?=.*[0-9])(?=.*[a-zA-Z])([\S]+)$/,
                message: 'Password must include at least one letter and a number'
            })]
        }
    });

    exports.default = _emberData.default.Model.extend(Validations, {
        email: _emberData.default.attr('string'),
        new_password: _emberData.default.attr('string'),
        token: _emberData.default.attr('string')
    });
});
define('jobbio-frontend/helpers/contains', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.contains = contains;
  function contains([a, b]) {
    return a.includes(b) ? true : false;
  }

  exports.default = Ember.Helper.helper(contains);
});
define('jobbio-frontend/routes/crunchbase-jobs', ['exports', 'jobbio-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    queryParams: {
      source: { refreshModel: true }
    },
    titleToken: 'Get A Demo - Start Hiring Better Talent Today',
    headTags: function () {
      return [{
        type: 'meta',
        attrs: {
          name: 'description',
          content: 'Crunchbase Jobs allows you to add your open roles to your Cruchbase profile. These roles are promoted to Crunchbase\'s 9m monthly users.'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:title',
          content: 'Crunchbase Jobs | Jobbio'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:description',
          content: 'Crunchbase Jobs allows you to add your open roles to your Cruchbase profile. These roles are promoted to Crunchbase\'s 9m monthly users.'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:url',
          content: 'https://jobbio.com/crunchbase-jobs'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:type',
          content: 'website'
        }
      }, {
        type: 'link',
        tagId: 'canonical-link',
        attrs: {
          rel: 'canonical',
          content: 'https://jobbio.com/crunchbase-jobs'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'twitter:site',
          content: '@Jobbio'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'twitter:title',
          content: 'Crunchbase Jobs | Jobbio'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'twitter:description',
          content: 'Crunchbase Jobs allows you to add your open roles to your Cruchbase profile. These roles are promoted to Crunchbase\'s 9m monthly users.'
        }
      }];
    },
    model(params) {
      return { image: _environment.default.APP.CLOUD_FRONT_HOST + "images/site/", source: params.source };
    },
    afterModel() {
      var APP_ID = _environment.default.APP.INTERCOM;
      window.$("head").append(`
      <script>
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
      </script>
    `);
      window.Intercom('update');
      var logged_in = {
        app_id: APP_ID,
        action_color: "#ff4f4f",
        custom_launcher_selector: "#frontHeadProductLiveChat",
        email: this.get('currentUser.current.user.email'),
        user_id: this.get('currentUser.current.user.id'),
        name: `${this.get('currentUser.current.user.first_name')} ${this.get('currentUser.current.user.last_name')}`,
        utm_source: this.get('currentUser.current.company.source'),
        company: { company_id: this.get('currentUser.current.company.id'),
          name: this.get('currentUser.current.company.name'),
          created_at: this.get('currentUser.current.company.created_at') }
      };
      var logged_out = {
        app_id: APP_ID,
        action_color: "#ff4f4f"
      };
      var sess = this.get('currentUser');
      if (!sess.get('session.isAuthenticated')) {
        window.Intercom('boot', logged_out);
      } else {
        window.Intercom('boot', logged_in);
      }
    },
    setupController(controller) {
      this._super(...arguments);
      setTimeout(function () {
        controller.validateForm();
      }, 1000);

      // Reset all properties of controller
      controller.setProperties({
        company_name: '',
        website: '',
        ats: '',
        feed: '',
        errors: {},
        didAgree: false
      });
    }
  });
});
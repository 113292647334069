define('jobbio-frontend/controllers/user/login', ['exports', 'jobbio-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    logging: false,
    cloudfront: _environment.default.APP.CLOUD_FRONT_HOST,
    visible: 'password',
    backgroundImage: Ember.computed(function () {
      // Produces
      // Binding style attributes may introduce cross-site scripting vulnerabilities warning
      // but is escaped
      return new Ember.String.htmlSafe(this.get('cloudfront') + "images/site/login_background.jpg");
    }),
    actions: {
      authenticate() {
        this.set('logging', true);
        this.set('response', null);
        var credentials = this.getProperties('identification', 'password'),
            authenticator = 'authenticator:token';
        this.get('session').authenticate(authenticator, credentials).catch(reason => {
          this.set('logging', false);
          this.set('response', reason);
        });
      },
      passwordVisibility: function (visibility) {
        if (visibility == 'password') {
          this.set('visible', "text");
        } else if (visibility == 'text') {
          this.set('visible', "password");
        }
      },
      focus(state) {
        this.set('focused', state);
      }
    }
  });
});
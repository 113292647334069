define("jobbio-frontend/models/user", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    role: _emberData.default.attr("string"),
    first_name: _emberData.default.attr("string"),
    last_name: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    country_code: _emberData.default.attr("string"),
    phone: _emberData.default.attr("string"),
    password: _emberData.default.attr("string"),
    is_active: _emberData.default.attr("number"),
    status: _emberData.default.attr("string"),
    avatar: _emberData.default.attr("string"),
    source: _emberData.default.attr("string"),
    last_seen: _emberData.default.attr("date"),
    updated_at: _emberData.default.attr("date"),
    created_at: _emberData.default.attr("date")
  });
});
define("jobbio-frontend/routes/promotions", ["exports", "jobbio-frontend/routes/base-route"], function (exports, _baseRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    model: function () {
      window.location.replace("https://content.jobbio.com/betakit");
    }
  });
});
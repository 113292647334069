define('jobbio-frontend/controllers/search/results', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isMobile: false,
    userAgent: Ember.inject.service(),
    init() {
      this._super(...arguments);
      if (this.get('userAgent.device.isMobile')) {
        this.set('isMobile', true);
      }
    }
  });
});
define('jobbio-frontend/routes/job/apply', ['exports', 'jobbio-frontend/routes/base-route', 'jobbio-frontend/config/environment'], function (exports, _baseRoute, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseRoute.default.extend({
    currentUser: Ember.inject.service(),
    renderTemplate: function () {
      this.render('job/apply', { into: 'application' });
    },
    model: function (params) {
      if (!this.get('currentUser.current')) {
        window.location.assign(_environment.default.APP.HTTP_PROTOCOL + _environment.default.APP.DOMAIN);
      }
      params = this.paramsFor('job');
      return this.get('store').queryRecord('job-slug', params).then(function (response) {
        var source, app_source;
        if (params.source) {
          source = params.source;
        } else {
          source = 'job';
        }
        if (params.app_source) {
          app_source = params.app_source;
        } else {
          app_source = 'job';
        }
        return { job: response, tracking: { ccuid: params.ccuid, source: source, app_source: app_source } };
      });
    }
  });
});
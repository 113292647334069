define('jobbio-frontend/components/channel/channel-feed-section', ['exports', 'jobbio-frontend/components/pagination-component'], function (exports, _paginationComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginationComponent.default.extend({
    modelType: 'channel-feed-item',
    type: 'all',
    searchTerm: '',
    onTypeChanged: Ember.observer('searchTerm', function () {
      this.set('cursor', '');
      this.setProperties({
        hasLoadedInitialy: false,
        hasMore: true,
        objects: []
      });
      this.send('loadMore');
    }),
    onChannelSelected: Ember.observer('channel', function () {
      this.set('cursor', '');
      this.setProperties({
        hasLoadedInitialy: false,
        hasMore: true,
        objects: []
      });
      this.send('loadMore');
    }),
    params: Ember.computed('cursor', function () {
      var params = this._super();
      params['channel_id'] = this.get('channel.id');
      params['type'] = this.get('type');
      params['search'] = this.get('searchTerm');
      if (this.get('channel.type') == "company") {
        params['page_size'] = 5;
      }
      return params;
    }),
    isTopicChannel: Ember.computed('channel.type', function () {
      return this.get('channel.type') == "topic" || this.get('channel.type') == "activity";
    }),
    didRender() {
      this._super(...arguments);
      window.$('#channel-view-more').attr('href', '#');
    },
    init() {
      this._super(...arguments);
      this.get('store').queryRecord('feed-filter', { slug: this.get('channel.slug') }).then(response => {
        this.set('filters', response);
      });
    },
    actions: {
      setType: function (type) {
        this.set('type', type);
      }
    }
  });
});